<template>
  <v-img
    v-if="namespace === 'Home'"
    gradient="to top, rgba(255, 255, 255, .75), rgba(255, 255, 255, .75)"
    src="/static/1619-altered.jpg"
    max-height="400px"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col />
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-img>

  <v-sheet
    v-else
    color="grey lighten-3"
    height="9vh"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col />
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
  export default {
    computed: {
      breadcrumbs () {
        const home = { text: 'Home', to: '/' }

        switch (this.namespace) {
          case 'About Us': return [home, { text: 'About Us' }]
          case 'Shows': return [home, { text: 'Shows' }]
          default: return []
        }
      },
      namespace () {
        return this.$route.name
      },
      title () {
        switch (this.namespace) {
          case 'About Us': return ''
          case 'Shows': return 'Shows'
          default: return ''
        }
      }
    }
  }
</script>
